/* BecomeAPartner.css */

.partner-page {

    background-color: #181818;
  }
/* Mobile responsiveness */
@media (max-width: 768px) {
  .step-container {
    flex-direction: column;
  }

  .step-item {
    flex-direction: column;
    align-items: center;
  }

  .step-item:nth-child(2) {
    flex-direction: column; /* Revert to column layout on small screens */
  }

  .step-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .step-number {
    margin-left: 0;
  }
}

  /* Responsive */
  @media (max-width: 992px) {
    .info-content {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .info-content {
      grid-template-columns: 1fr;
    }
  
    .hero-content1 h1 {
      font-size: 36px;
    }
  
    .hero-content1 p {
      font-size: 16px;
    }
  }
  