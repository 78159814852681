/* Transportation section container */
/* Transportation section container with background image */
.transportation-section {
    padding: 50px;
    text-align: center;
    background-image: url('../../../../assets/suitsjourneyBackground.webp'); /* Add the background image */
    background-size: cover; /* Ensure the image covers the entire section */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent image repetition */
    color: white; /* Ensure text is readable over the background */
  }
  
  
  /* Container for the three icons */
  .transportation-icons {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }

  .transport-image {
    width: 20%; /* Adjust size of the images */
    height: auto;
    margin-bottom: 10px;
    margin-left: 20px;
    margin-right: 20px;
  }
  
  /* Styling for the heading and description */
  .transportation-description {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px; /* Constrain width for readability */
    margin: 0 auto;
  }
  
  .transportation-description h2 {
    font-size: 2.5vw;
    font-weight:600;
    color: white;
    margin-bottom: 0; /* Remove bottom margin for inline layout */
    text-align: start;
    width: 40%;
    align-self: flex-start;
  }
  
  .transportation-description p {
    font-size: 1.3vw;
    color: white;
    width: 50%;
    margin-left: 20px; /* Adds space between h2 and p */
    line-height: 1.6;
    text-align: start; /* Align paragraph text to the right */
  }

  @media (max-width: 650px) {
    .transport-image {
      width: 30%; /* Adjust size of the images */
    }
    .transportation-description {
      flex-direction: column; /* Ensure vertical stacking */
    }
    
    .transportation-description h2 {
      font-size: 4vw;
      width: 100%;
      margin-bottom: 10px; /* Add spacing under h2 */
    }
  
    .transportation-description p {
      font-size: 3vw;
      margin-left: 0; /* Remove left margin for proper alignment */
      width: 100%;
    }
  }
  