.about-us {
  color: white;
  background-color: #181818;
  overflow-x: hidden;
  overflow-y: hidden;
}

.hero-section {
  text-align: center;
  background-position: center;
  padding: 20px 0;
  padding-top: 7%;
  background-color: black;
  width: 100%; /* Full width */
  box-sizing: border-box; /* Ensure padding doesn't affect width */
  display: flex;
  justify-content: center;
  background-size: cover;
}

.hero-content h1 {
  font-size: 3rem;
}

.hero-content p {
  font-size: 1.2rem;
}

/* Responsive Vision-Mission Section */
.vision-mission {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  padding: 50px;
  flex-wrap: wrap; /* Enable wrapping for smaller screens */
  width: 100%;
}

.vision-mission-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 30%;
  margin-left: 5%;
  order: 1; /* Default order for larger screens */
}

.image-box {
  width: 30%;
  margin-right: 100px;
  order: 2; /* Default order for larger screens */
  padding-right: 100px;
}

.image-box img {
  width: 100%; /* Adjust width to prevent overflow */
  height: auto;
}

.vision-mission .text {
  font-size: 1rem;
  font-weight: 300;
  text-align: start;
  color: white; /* Ensure text is readable */
  line-height: 1.5; /* Improve text readability */
}

.vision-mission .subtext{
  font-size: 1rem;
  font-weight: 100;
  padding-top: 5px;
  padding-bottom: 10px;
  text-align: start;
  color: rgb(209, 209, 209); /* Ensure text is readable */
  line-height: 1.8; /* Improve text readability */
}

.vision-mission-btn button{
  display: flex;
  background-color: #0032CF;
  color: white;
  border: none;
  padding: 12px 120px;
  width: 20%;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 100px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.vision-mission-btn button:hover {
background-color: #001f83;
}


.vision-mission .title {
  font-size: 1.5rem; /* Make the titles bold and prominent */
  font-weight: 700;
  color: white; /* Ensure title color stands out */
  text-align: start;
}

/* Responsive CTA Section */
.cta1 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px; /* Fixed height to ensure consistency */
  overflow: hidden;
  padding-bottom: 50px;
  padding-top: 50px;
}

.cta1 img {
  width: 90%;
  height: auto;
  object-fit: cover; /* Ensures the image covers the container */
  transition: transform 0.3s ease-in-out; /* Smooth transition when scaling */
  transform-origin: center; /* Zooms in from the center */
}

.cta1-content {
  position: absolute;
  z-index: 10;
  color: white;
  text-align: center;
  width: 50%;
  font-size: 2.8rem;
  font-weight: 5000;
  margin-bottom: 20px;
}

.cta1-title {
  font-size: 1rem;
  font-weight: 500;
  color: white;
  text-align: center;
  padding-top: 50px;
}

.cta1 h2 {
  font-size: 2.8rem;
  font-weight: 5000;
  margin-bottom: 20px;
}

.cta1 .button {
  background-color: #0032CF;
  color: white;
  border: none;
  padding: 12px 30px;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 4px;
}

.cta1 .button:hover{
  background-color: #001f83;
}

/* Meet Our Team Section */
.meet-our-team {
  background-size: contain;
  background-position: center;
  padding: 50px 0;
  text-align: center;
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-left: 35%;
  padding-right: 30%;
  padding-top: 5%;
}

.team-grid p{
  font-size: 1rem;
  font-weight: 300;
  color: white; /* Ensure text is readable */
  line-height: 1.8; /* Improve text readability */
  width: 110%;
}

.team-member {
  padding-left: 10%;
  padding-right: 10%;
  border-radius: 10px;
  text-align: start;
  align-items: center;
}
.team-header h2 {
  padding-top: 100px;
  font-size: 2rem;
  margin-bottom: 10px;
  color: white; /* Adjust if needed */
}

.team-header p {
  font-size: 1.1rem;
  color: white; /* Adjust if needed */
  font-weight: 200;
}

.team-photo {
  width: 113%;
  height: 30%;
}

.interactive-logo-container {
  width: 100%;
  perspective: 1000px;
  cursor: none;
}

.interactive-logo-container::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 1;
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
  .vision-mission-content {
    max-width: 80%; /* Take full width on smaller screens */
    order: 2; /* Place content after the image */
    align-items: center;
  }

  .image-box {
    width: 40%; /* Take full width on smaller screens */
    margin-left: 300px;
    order: 1; /* Place image first */
  }

  .cta1 img {
    height: 600px;
    width: 90%;
  }

  .cta1-title{
    font-size: 0.8rem;
    font-weight: 500;
  }


  .team-photo {
    width: 300px;
    height: 200px;
  }
  
  .cta1 h2 {
    font-size: 2.1rem;
    font-weight: 5000;
    margin-bottom: 16px;
  }
  
  .cta1 .button {
    padding: 10px 28px;
    font-size: 0.8rem;
  }

  .team-grid{
    grid-template-columns: repeat(2, 1fr); /* 2 columns for tablets */
    width: 90%;
  }
  .team-grid p {
    width: 80%;
  }
}

@media (max-width: 992px) {

  .vision-mission {
    padding: 20px; /* Reduce padding on smaller screens */
    flex-direction: column; /* Stack elements on smaller screens */
    align-items: flex-start;
  }

  .vision-mission-content{
    max-width: 80%;
  }
  .image-box {
    width: 40%; /* Full width on smaller screens */
    margin-left: 250px;
  }

  .cta1 img {
    height: 500px;
    width: 90%;
  }

  .team-photo {
    width: 250px;
    height: 140px;
    min-width: 150px; /* Minimum width to ensure visibility */
  }

  .team-member {
    width: 100%; /* Ensure the team member box takes full width */
    text-align: start; /* Align content in the center for mobile */
  }

  .team-member p {
    font-size: 0.8rem; /* Adjust font size for mobile */
    width: 60%;
    
  }

  .cta1-title{
    font-size: 0.6rem;
    font-weight: 500;
  }
  
  .cta1 h2 {
    font-size: 1.6rem;
    font-weight: 5000;
    margin-bottom: 16px;
  }
  
  .cta1 .button {
    padding: 8px 24px;
    font-size: 0.66rem;
  }

}

@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 2.5rem; /* Adjust font size for tablets */
    margin-top: 20%;
    text-align: center;
  }

  .vision-mission {
    width: 100%;
  }

  .vision-mission button{
    margin-left: 220px;
  }

  .vision-mission-content{
    max-width: 90%;
    text-align: center;
  }

  .vision-mission .title {
    text-align: center;
  }

  .vision-mission-content p {
    text-align: center;
  }

  .cta1 img {
    height: 400px;
    width: 90%;
  }

  .cta1-content {
    width: 80%;
    text-align: center;
    align-content: center;
    font-size: 2rem; /* Adjust font size for mobile */
  }

  .team-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for tablets */
    padding-right: 20px;
  }

  .team-grid p{
    width: 120%;
  }

  .team-photo {
    width: 200px;
    height: 150px;
    min-width: 150px; /* Minimum width to ensure visibility */
  }

  .team-member {
    width: 50%; /* Ensure the team member box takes full width */
    text-align: start; /* Align content in the center for mobile */
    padding-right: 40px;

  }

  .cta1-title{
    font-size: 0.6rem;
    font-weight: 500;
  }
  
  .cta1 h2 {
    font-size: 1.4rem;
    font-weight: 5000;
    margin-bottom: 16px;
  }
  
  .cta1 .button {
    padding: 8px 24px;
    font-size: 0.6rem;
  }

}

@media (max-width: 576px) {

  .vision-mission {
    width: 90%;
  }

  .vision-mission button{
    margin-left: 90px;
  }

  .hero-content h1 {
    font-size: 2rem; /* Smaller text for mobile */
    margin-top: 20%;
  }

  .image-box {
    width: 70%; /* Full width for mobile */
    margin-left: 40px;
  }

  .hero-content p {
    font-size: 1rem;
  }

  .cta1 img {
    height: 300px;
    width: 90%;
  }

  .cta1-content {
    font-size: 1.5rem; /* Smaller font size */
  }

  .team-member{
    padding-right: 10%;
    width: 100%;
  }

  .team-grid {
    grid-template-columns: (2, 1fr); /* 1 column for mobile */
    padding-left: 5%;
    padding-right: 5%;
  }

  .team-photo {
    width: 150px;
    height: 115px;
    min-width: 150px; /* Minimum width to ensure visibility */
  }
  

  .team-member h3{
    width: 80%;
    font-size: 0.9rem;
  }

  .team-member p{
    font-size: 0.6rem; /* Adjust font size for mobile */
    width: 80%;
  }

  .cta1-title{
    font-size: 0.4rem;
    font-weight: 500;
  }
  
  .cta1 h2 {
    font-size: 1rem;
    font-weight: 5000;
    margin-bottom: 8px;
  }
  
  .cta1 .button {
    padding: 6px 18px;
    font-size: 0.5rem;
  }
}

@media (max-width: 350px) {
  .hero-content h1 {
    font-size: 1.8rem; /* Smaller text for mobile */
    margin-top: 20%;
  }

  .image-box {
    width: 80%; /* Full width for mobile */
    margin-left: 0;
  }

  .vision-mission{
    max-width: 80%;
  }

  .vision-mission .button {
    padding: 10px 45px;
    font-size: 1.2rem;
  }

  .hero-content p {
    font-size: 1rem;
  }

  .cta1 img {
    height: 300px;
    width: 90%;
  }

  .cta1-content {
    font-size: 1.5rem; /* Smaller font size */
  }

  .team-grid {
    grid-template-columns: 1fr; /* 1 column for mobile */

    width: 89%;
  }

  .team-member{
    padding-right: 10%;
  }

  .team-photo {
    width: 160px;
    height: 115px;
    min-width: 150px; /* Minimum width to ensure visibility */
  }

  .team-photo img{
    width: 90%; /* Ensure the photo takes full available width */
    height: auto; /* Maintain aspect ratio */
  }

  
  .team-member p{
    font-size: 0.6rem; /* Adjust font size for mobile */
  }

  .cta1-title{
    font-size: 0.4rem;
    font-weight: 500;
  }
  
  .cta1 h2 {
    font-size: 1rem;
    font-weight: 5000;
    margin-bottom: 8px;
  }
  
  .cta1 .button {
    padding: 6px 18px;
    font-size: 0.5rem;
  }
  
}
