/* Policies.css */

/* General Styles */
.terms-body {
    font-family: "Patrick Hand";
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #f4f4f4;
    color: #333;
    padding-top: 80px; /* Padding for the navbar */
}

/* Container */
.terms-container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 80px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

/* Header */
.terms-header {
    text-align: center;
    margin-bottom: 20px;
    font-family: "Patrick Hand";
}

.terms-header h1 {
    font-size: 2.5em;
    margin: 0;
    color: #292a2b;
    font-family: "Patrick Hand";
}

/* terms Section */
.terms-section {
    margin-bottom: 20px;
    text-align: left; /* Align text to the left */
    font-family: "Patrick Hand";
}

.terms-section h2 {
    font-size: 1.8em;
    margin-bottom: 10px;
    color: #007BFF;
    font-family: "Patrick Hand";
}

.terms-section p {
    line-height: 1.6;
    margin-bottom: 10px;
    font-family: "Patrick Hand";
}

/* New Styles */
.terms-subtitle {
    font-size: 1.5em;
    color: #555;
    font-family: "Patrick Hand";
    margin-bottom: 10px;
}

.terms-stitle {
    font-family: "Patrick Hand";
    font-size: 1.2em;
    color: #777;
    margin-bottom: 10px;
}

/* create a sub-subtitle */
.terms-ssubtitle {
    font-family: "Patrick Hand";
    font-size: 1em;
    color: #999;
    margin-bottom: 10px;
}

.terms-paragraph {
    font-family: "Patrick Hand";
    font-size: 1em;
    color: #333;
    margin-bottom: 10px;
}

.terms-bullet-points {
    font-family: "Patrick Hand";
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 10px;
}

.terms-underlined-title {
    font-family: "Patrick Hand";
    font-size: 1.8em;
    color: #007BFF;
    text-decoration: underline;
    margin-bottom: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .terms-header h1 {
        font-size: 2em;
    }

    .terms-terms-section h2 {
        font-size: 1.5em;
    }
}

@media (max-width: 480px) {
    .terms-header h1 {
        font-size: 1.5em;
    }

    .terms-section h2 {
        font-size: 1.2em;
    }
}