/* Slider container: Full width */
.slider {
    width: 100%;
    overflow: hidden;
    position: relative;
}

@media (max-width: 650px){
    .slider {
        margin-top: -10%;
        padding-bottom: 10%;
    }
}

.slider-wrapper {
    display: flex;
    animation: slide infinite linear 40s; /* Continuous slow animation */
}

@keyframes slide {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

/* Each slide */
.slide {
    min-width: 33.33%; /* Display 3 images at a time for the first slider */
    margin: 0 -5%; /* Gap between images */
    display: flex;
    justify-content: center;
}

.slide img {
    width: 55%;
    height: auto;
    object-fit: cover;
}

/* Alternating padding styles for the first slider */
.padding-top {
    padding-top: 10%;
    padding-bottom: 1%;
}

.padding-bottom {
    padding-top: 1%;
    padding-bottom: 10%;
}

/* Styling for the second slider */
.secondary-slider-container {
    background-color: #f1f0fe;
    padding: 3% 0;
}

.secondary-slider-wrapper {
    display: flex;
    justify-content: space-between; /* Ensure images are next to each other */
    animation: slide-horizontal infinite linear 40s;
}

@keyframes slide-horizontal {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

.secondary-slide {
    flex: 1; /* Ensures each image takes up equal space */
    margin-right: -14%;
}

.secondary-slide img {
    width: 20%; /* Ensure the images in the second slider are 100% width */
    height: auto;
    object-fit: contain;
}

@media (max-width: 650px){
    .secondary-slide img {
        width: 30%;
    }
}

.secondary-slider-wrapper {
    display: flex;
    justify-content: space-between; /* Evenly distribute the space between images */
    gap: 5px; /* Add a small gap between images (adjust as necessary) */
}


.static-image-container {
    width: 100vw; /* Full viewport width */
    overflow: hidden; /* Prevent horizontal scrollbars */
    margin: 0; /* Remove any margins */
}

.static-full-width-image {
    width: 100%; /* Make the image take the full width of the screen */
    height: auto; /* Maintain aspect ratio */
}
