/* ContactUs.css */

.contact-us-section {
    background-image: url('../../../assets/meetourteambackground.webp');
    background-size: cover;
    background-position: center;
    padding: 80px 20px;
    text-align: center;
}

.contact-us-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 40px;
    border-radius: 10px;
}

.contact-us-container h2 {
    font-size: 32px;
    color: white;
    margin-bottom: 10px;
}

.contact-us-container p {
    font-size: 16px;
    color: #ccc;
    margin-bottom: 30px;
}

.contact-us-form {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.form-input{
    box-sizing: border-box;
}

.form-input{
    width: 100%;
    margin-bottom: 20px;
    border-radius: 5px;
    padding: 15px;
    background: transparent;
    border: 1px solid white;
    font-size: 16px;
    color: white;
}

.form-input-group {
    width: 100%;
    border-radius: 5px;
    padding: 15px;
    background: transparent;
    border: 1px solid white; /* Add white border to input fields */
    font-size: 16px;
    color: white; /* Ensure the text is white */
}

.form-input-group {
    display: flex;
    align-items: start;
    border: none;
    gap: 20px;
    padding: 0px;
}

.form-input-group:nth-child(2) {
    align-self: end;
    align-items: end;
}

.form-input-group input {
    width: 100%;
    box-sizing: border-box; 
    font-family: 'Plus Jakarta Sans', sans-serif;

}

textarea.form-input {
    height: 150px;
    resize: none;
}

textarea{
    font-family: 'Plus Jakarta Sans', sans-serif;
}

.submit-btn {
    background-color: #0032cf;
    color: white;
    border: none;
    width: 33%;
    padding: 15px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submit-btn:hover {
    background-color: rgb(0, 54, 162);
}

/* Style the placeholder text to be white */
::placeholder {
    color: white;
    opacity: 1; /* Ensures full opacity */
}

:-ms-input-placeholder { /* For Internet Explorer */
    color: white;
}

::-ms-input-placeholder { /* For Edge */
    color: white;
}

/* Ensure the select dropdown matches the form input styling */
select.form-input {
    background: transparent;
    color: white;
    width: 100%;
    padding: 10px;
}

/* Optional: Ensures the options themselves have default colors */
select.form-input option {
    width: 10%;
    color: black; /* Option text color */
    border-color: transparent;
    background: white; /* Option background */
}
