/* Base Styles */
.hero1 {
  background-size: cover;
  background-position: center;
  height: 100vh;
  min-height: 400px;
  position: relative;
  margin-top: 60px;
  z-index: 1;
  background-color: #ffffff;
  opacity: 1.4;
}

.hero-content1 {
  position: absolute;
  bottom: 10%;
  left: 5%;
  color: black;
  text-align: left;
  width: 90%;
  max-width: 1200px;
}

.hero-content1 h1 {
  font-size: 5rem;
  font-weight: 750;
  margin: 0;
}

.hero-content1 p {
  font-size: clamp(16px, 2vw, 18px);
  margin-bottom: 20px;
}

.hero-content1 span {
  color: #3b82f6;
  font-weight: bold;
}

.cta-button1 {
  align-self: center;
  background-color: #0032CF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: clamp(14px, 1.5vw, 16px);
  font-weight: bold;
  transition: background-color 0.3s ease;
  text-align: center;
  width: 24%;
  height: 70px;
}

.cta-button1:hover {
  background-color: #00319a;
}

.blue-section {
  background-color: #4166DB;
  padding: 30px 0;
  display: flex;
  justify-content: center;
}

.blue-section-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  width: 60%;
  color: white;
  padding: 0 20px;
  flex-wrap: wrap;
}

.blue-section-content p {
  font-size: clamp(24px, 4vw, 40px);
  margin-right: 10px;
  width: 100%;
  text-align: start;
  /* margin-left: -60px; */
  font-weight: 700;
  flex: 1 1 30px;
}

.blue-section-content span {
  font-size: clamp(24px, 10vw, 40px);
  color: #0032CF;
  text-align: start;
  font-weight: 900;
  
}

/* Media Queries */
@media (max-width: 1200px) {

  .hero1{
    height: 100%;
  }

  .hero-content1 h1{
    bottom: 15%;
    left: 5%;
    font-size: 4rem;
    
  }

  .cta-button1 {
    margin-right: 25px;
  }

  .blue-section-content {
    max-width: 90%;
  }

  .cta-button1 {
    max-width: 250px;
  }
}

@media (max-width: 992px) {
  .hero-content1 h1 {
    font-size: clamp(30px, 5vw, 70px);
  }

  .blue-section-content {
    flex-direction: column;
    text-align: center;
  }

  .cta-button1 {
    width: 40%;
    max-width: none;
    margin-right: 20px;
  }

  .blue-section-content p {
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .hero1 {
    margin-top: 60px;
  }

  .hero-content1 {
    bottom: 10%;
    left: 5%;
    text-align: center;
  }

  .cta-button1 {
    padding: 12px 24px;
    margin-right: 20px;

  }

  .blue-section-content p {
    margin-right: 0;
    width: 100%;
  }
}

@media (max-width: 576px) {
  .hero1 {
    margin-top: 40px;
    height: 80vh;
  }

  .blue-section {
    display:block;
  }

  .cta-button1 {
    width: 50%;
    max-width: none;
    margin-right: 20px;
  }

  .blue-section-content p {
    font-size: clamp(24px, 4vw, 40px);
    margin-right: 10px;
    width: 100%;
    text-align: center;
    font-weight: 700;
  }

  .blue-section-content {
    padding: 0 10px;
    width: 100%;
  }
}

@media (max-width: 350px) {
  .hero-content1 h1 {
    font-size: clamp(24px, 4vw, 50px);
  }

  .cta-button1 {
    font-size: 12px;
    padding: 10px 20px;
  }

  .blue-section {
    padding: 20px 0;
  }
}
