.delete-account-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: 100vh;
    padding: 2rem 1rem;
    background-color: var(--bg-light);
  }
  
  .delete-account-card {
    width: 100%;
    max-width: 800px;
    background: white;
    border-radius: var(--radius-md);
    box-shadow: var(--shadow-md);
    overflow: hidden;
  }
  
  .delete-account-deletion-details {
    padding: 0 2rem 1rem;
  }