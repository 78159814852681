/* Ultimate Travel Section */
.ultimate-section {
    margin-top: -60px;
    padding-bottom: 100px;
    padding-top: 20px;
  }
  
  .ultimate-content {
    display: flex;
    color: black;
    align-items: flex-start; /* Align text and image at the top */
    text-align: start;
    flex-wrap: wrap; /* Allow content to wrap if it overflows */
  }
  
  .ultimate-content h2 {
    flex: 3;
    margin-left: 50px;    
    padding-top: 10%;
    font-size: 4.5vw;
    overflow: visible; /* Allow text to overflow beyond the image if necessary */
    align-self: flex-end; /* Align the paragraph at the bottom of the flex container */

  }
  
.ultimate-image {
    position: absolute; /* Position above other elements */
    top: 0; /* Align to the top of the container */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Adjust for perfect centering */
    width: 30vw; /* Maintain a responsive width */
    height: auto;
    z-index: 9999; /* Ensure it's above all other elements */
    pointer-events: none; /* Prevent interaction if needed */
}
  
  .ultimate-content p {
    flex: 2;
    margin-left: 15%;
    margin-right: 5%;
    font-size: 1.5vw;
    word-wrap: break-word;
    overflow: visible;
    align-self: center; /* Align the paragraph at the bottom of the flex container */
  }

  @media (max-width: 980px){
    .ultimate-content {
      margin-top: 5%;
    }
  }

  @media (max-width: 650px) {
    .ultimate-content {
      margin-top: 20%;
      display: flex;
      flex-direction: row; /* Place items in a row */
      align-items: center; /* Align items vertically */
      justify-content: space-between; /* Add space between text and image */
    }
  
    .ultimate-image {
      flex: 1;
      position: static; /* Reset positioning to fit in the row */
      transform: none; /* Reset transform to prevent offset */
      width: 40%; /* Adjust width to fit better in the row */
      height: auto;
      z-index: auto; /* Reset z-index */
      pointer-events: auto; /* Allow interactions if needed */
    }
  
    .ultimate-content h2 {
      font-size: 5vw; /* Adjust font size for smaller screens */
      padding-top: 0; /* Remove extra padding */
      flex: 1;
      text-align: left;
      margin-left: 7%;
      margin-bottom: 30%;
      margin-right: 10px;
    }
  
    .ultimate-content p {
      font-size: 2.5vw; /* Adjust font size for readability */
      margin: 0; /* Remove margins for better alignment */
      flex: 1;
      text-align: left;
      margin-left: 7%;
      margin-top: -30%;
      margin-right: 35%;
    }
  }

  @media (max-width: 395px) {
    .ultimate-content {
      margin-top: 25%;
    }
  }

  @media (max-width: 280px) {
    .ultimate-content {
      padding-top: 20%;
    }
  }
  
  .cta-boxes {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; /* Allow wrapping for responsiveness */
    gap: 70px; /* Add spacing between the boxes */
    margin-top: 50px;
  }
  
  .cta-box {
    background-color: #ff7500;
    color: #fff;
    border: none;
    text-align: center;
    border-radius: 5px;
    display: inline-flex; /* Adjust size to content */
    align-items: center;
    padding: 10px 15px; /* Add some padding around the content */
    box-sizing: border-box;
  }
  
  .cta-box img {
    margin-right: 8px;
    width: 20px;
    height: auto;
  }
  
  .cta-box span {
    white-space: nowrap; /* Prevent text wrapping */
    font-size: 0.8rem;
  }
  
  /* Media query for 650px */
  @media (max-width: 650px) {
   .cta-boxes{
    margin-top: 10px;
  }
    .cta-box.hidden {
      display: none; /* Hide extra CTA boxes */
    }
  
    .cta-box {
      flex: 1 1 auto; /* Allow responsive sizing */
    }

    .cta-box span {
      white-space: nowrap; /* Prevent text wrapping */
      font-size: 1.5vw;
    }

    .cta-box img {
      width: 10px;
    }

    .cta-boxes{
      gap: 15px
    }
  }

  @media (max-width: 380px) {
    .cta-box.hidden {
      display: none; /* Hide extra CTA boxes */
    }
  
    .cta-box {
      display: flex;
      padding: 8px 10px;
    }

    .cta-box span {
      font-size: 1.5vw;
    }

    .cta-box img {
      width: 8px;
    }

    .cta-boxes{
      gap: 15px
    }
  }
  