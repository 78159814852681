.delete-account-impact-section {
    display: grid;
    gap: 1.5rem;
    margin: 2rem 0;
  }
  
  .delete-account-impact-card {
    background: white;
    border-radius: var(--radius-md);
    box-shadow: var(--shadow-sm);
    overflow: hidden;
    transition: var(--transition);
  }
  
  .delete-account-impact-card:hover {
    transform: translateY(-3px);
    box-shadow: var(--shadow-md);
  }
  
  .delete-account-impact-header {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 1.25rem 1.5rem;
    background-color: var(--bg-light);
    border-bottom: 1px solid var(--border-color);
  }
  
  .delete-account-impact-icon {
    width: 24px;
    height: 24px;
    color: var(--danger-color);
  }
  
  .delete-account-impact-card h3 {
    font-size: 1.2rem;
    color: var(--text-color);
  }
  
  .delete-account-impact-list {
    padding: 1.5rem;
    list-style: none;
  }
  
  .delete-account-impact-item {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    padding: 0.75rem 0;
    border-bottom: 1px dashed var(--border-color);
  }
  
  .delete-account-impact-item:last-child {
    border-bottom: none;
  }
  
  .delete-account-bullet-icon {
    width: 18px;
    height: 18px;
    color: var(--danger-color);
    flex-shrink: 0;
    margin-top: 0.25rem;
  }