.delete-account-warning-banner {
    display: flex;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #fff6f6;
    color: var(--danger-color);
    font-size: 0.95rem;
    gap: 0.75rem;
    border-left: 4px solid var(--danger-color);
    margin: 0 2rem;
    border-radius: var(--radius-sm);
    transform: translateY(-1rem);
  }
  
  .delete-account-warning-banner svg {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
  }