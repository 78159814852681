/* Policies.css */

/* General Styles */
.policy-body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #f4f4f4;
    color: #333;
    padding-top: 80px; /* Padding for the navbar */
}

/* Container */
.policy-container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 80px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

/* Header */
.policy-header {
    text-align: center;
    margin-bottom: 20px;
}

.policy-header h1 {
    font-size: 2.5em;
    margin: 0;
    color: #292a2b;
}

/* Policy Section */
.policy-section {
    margin-bottom: 20px;
    text-align: left; /* Align text to the left */
}

.policy-section h2 {
    font-size: 1.8em;
    margin-bottom: 10px;
    color: #007BFF;
}

.policy-section p {
    line-height: 1.6;
    margin-bottom: 10px;
}

/* New Styles */
.policy-subtitle {
    font-size: 1.5em;
    color: #555;
    margin-bottom: 10px;
}

.policy-stitle {
    font-size: 1.2em;
    color: #777;
    margin-bottom: 10px;
}

/* create a sub-subtitle */
.policy-ssubtitle {
    font-size: 1em;
    color: #999;
    margin-bottom: 10px;
}

.policy-paragraph {
    font-size: 1em;
    color: #333;
    margin-bottom: 10px;
}

.policy-bullet-points {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 10px;
}

.policy-underlined-title {
    font-size: 1.8em;
    color: #007BFF;
    text-decoration: underline;
    margin-bottom: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .policy-header h1 {
        font-size: 2em;
    }

    .policy-policy-section h2 {
        font-size: 1.5em;
    }
}

@media (max-width: 480px) {
    .policy-header h1 {
        font-size: 1.5em;
    }

    .policy-section h2 {
        font-size: 1.2em;
    }
}