.what-drives-us {
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  background: url('../../../../assets/whatDrivesUsbackgroundImage.webp') no-repeat center center; /* Ensures background centers */
  background-size: cover;
  background-position-y: 300px;
  z-index: 1;
  width: 100%;
  padding-bottom: 300px;
}

/* Additional media queries for mobile adjustments if necessary */
@media (max-width: 768px) {
  .what-drives-us {
    background-position-y: 550px; /* Adjust position for mobile */
  }
}

@media (max-width: 480px) {
  .what-drives-us {
    background-position-y: 660px; /* Adjust position for mobile */
  }
}

  
  .what-drives-us h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .what-drives-us p {
    font-size: 1.2em;
    margin-bottom: 40px;
    color: rgba(255, 255, 255, 0.8);
    width: 70%;
    max-width: 800px; /* To restrict width on larger screens */
  }
  
  .values-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    justify-content: center; /* Centers the grid horizontally */
    width: 70%;
    z-index: 2; /* Ensures grid is above the background */
    position: relative;
    padding: 20px;
    border-radius: 10px;
  }
  
  .value-item {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 20px;
    border-radius: 8px;
  }
  
  .value-item img {
    width: 20%;
    height: auto;
  }
  
  .value-text {
    text-align: start;
    display: flex;
    flex-direction: column;
  }
  
  .value-text h3 {
    margin: 0;
    font-size: 1.5em;
    padding-bottom: 10px;
  }
  
  .value-text p {
    margin: 0;
    font-size: 0.8em;
    color: rgba(255, 255, 255, 0.7);
    margin-top: 5px;
    width: 100%; /* Ensure full width for responsiveness */
  }
  
/* Explore Services section */
.explore-services {
  text-align: center;
  padding: 60px 20px;
  color: white;
  z-index: 2;
  position: relative;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -200px;
}

.explore-services h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.explore-services p {
  font-size: 1.2em;
  margin-bottom: 40px;
  color: rgba(255, 255, 255, 0.8);
}
  .services-grid {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;
  }
  
  .services-grid div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    transition: transform 0.3s ease;
    flex-basis: calc(33.33% - 30px); /* 3 in a row on desktop */
    max-width: calc(33.33% - 30px); /* Ensure three fit in a row */
    box-sizing: border-box;
  }
  
  .services-grid div:hover {
    transform: scale(1.05);
  }
  
  .services-grid span {
    font-size: 1.2em;
    font-weight: 500;
    text-align: center;
  }
  
  .services-grid small {
    font-size: 0.9em; /* Smaller font for subtext */
    color: rgba(255, 255, 255, 0.7); /* Lighter color for subtext */
    margin-top: 50px;
    text-align: center;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .services-grid div {
      flex-basis: calc(50% - 20px); /* 2 in a row for tablets */
      max-width: calc(50% - 20px);
    }
  }
  
  @media (max-width: 480px) {
    .services-grid div {
      flex-basis: 100%; /* Full width for mobile */
      max-width: 100%;
      padding: 15px;
    }
  }
  
/* Responsive Design */
@media (max-width: 768px) {
  .explore-services h2 {
    font-size: 2em;
  }

  .explore-services p {
    font-size: 1.1em;
  }

  .partner-button {
    padding: 10% 30%;
  }

  .services-grid div {
    padding: 10px;
    flex-basis: calc(50% - 20px); /* Slightly narrower for smaller screens */
    max-width: calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .explore-services h2 {
    font-size: 1.8em;
  }

  .explore-services p {
    font-size: 1em;
  }

  .services-grid {
    justify-content: center;
  }

  .services-grid div {
    flex-basis: 100%; /* Full width on very small screens */
    max-width: 100%;
    padding: 15px;
  }

  .partner-button {
    padding: 8% 25% !important;
  }

  .partner-text {
    padding-left: 0;
  }
  
  .partner-text h2 {
    font-size: 2.5em !important;
  }
  
  .partner-text h3 {
    font-size: 2.5em !important;
  }
}


  .partner-section {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
    margin-top: 40px; /* Add space between this section and the content above */
  }
  
  .partner-text {
    color: #fff; /* White text */
    padding-left: 3%;
  }
  
  .partner-text h2 {
    text-align: start;
    font-size: 3em;
    color: #0032CF; /* Blue color */
  }
  
  .partner-text h3 {
    text-align: start;
    font-size: 3em;
    color: white;
  }

  .partner-text h2, .partner-text h3 {
    margin: 0;
    padding: 0;
    line-height: 1.2; /* Adjust line-height if needed */
  }
  
  
  .partner-button {
    background-color: #0032CF; /* Blue button */
    color: white;
    padding: 2% 10%;
    border: none;
    margin-right: 28px;
    border-radius: 8px;
    font-size: 1.2em;
    cursor: pointer;
  }
  
  .partner-button:hover {
    background-color: #002498; /* Darker blue on hover */
  }
  
  
  
  @media (max-width: 768px) {
    .values-grid {
      grid-template-columns: 1fr;
    }
  
    .value-item img {
      width: 25%;
    }
  
    .value-text p {
      width: 100%;
    }
  
    .services-grid {
      flex-direction: column;
      align-items: center;
    }
  }
  