.Faqs-section {
    background: linear-gradient(to bottom, #FF6E26 90%, #181818 10%);
    background-position: center;
    /* padding-bottom: 40px; */
    text-align: center;
  
  }
  
.faq-container1 {
    background-color: #FF6E26;
    padding: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.faq-content1 {
    display: flex;
    flex-direction: column;
    width: 80%;
}

.faq-title1 {
    font-size: 3vw;
    font-weight: 200;
    color: white;
    margin-bottom: 30px;
    text-align: start;
    align-self: start;
}

.trego-app-footer {
    position: relative;
    margin-top: 50px;
    display: flex;
    justify-content: center; /* Center the entire footer content horizontally */
    align-items: center; /* Center the entire footer content vertically */
    padding: 20px;
    margin-bottom: -50px;
    
  }
  
  
  .trego-app-footer img {
    width: 90%; /* Full-width image */
    position: relative; /* Position for content overlay */
  }
  
    
  .trego-app-footer-content {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    align-items: flex-start; /* Align content to the left */
    position: absolute; /* Keeps content inside the image */
    padding-left: 25%;
    border-radius: 10px;
    height: 100%; /* Full height */
    width: 100%; /* Full width */
  }
  
  .trego-app-footer-content h3 {
    font-size: 3rem;
    margin-bottom: 10px;
    color: #FF6E26;
    margin: 0;
    padding: 0;
    line-height: 1.2;
  }
  
  .trego-app-footer-subscribe-button {
    background-color: #FF6E26;
    color: white;
    border: none;
    padding: 25px 35px;
    margin-right: 5px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 200;
  }



  .subscribe-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%; /* Full width */
    max-width: 550px; /* Adjust width as needed */
    border: 1px solid black;
    border-radius: 10px; /* Rounded corners */
    overflow: hidden;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 7%;
  }
  .subscribe-form .input-field {
    flex-grow: 1; /* Take up available space */
    padding-left: 15px;
    border: none;
    outline: none;
    font-size: 1rem;
  }
  
  /* Default placeholder styling */
  .subscribe-form .input-field::placeholder {
    color: black;
    font-size: 1.3rem;
    transition: all 0.3s ease; /* Smooth transition effect */
  }
  
  /* Hide placeholder when input is focused */
  .subscribe-form .input-field:focus::placeholder {
    color: transparent; /* Make the placeholder invisible */
  }

  @media (max-width: 1040px) {

    .trego-app-footer-content {
        padding-bottom: 90px;
        padding-top: 80px;
      padding-left: 25%;
    }
    .trego-app-footer-content h3 {
        
      font-size: 2rem;
    }
    .subscribe-form {
      max-width: 400px; /* Adjust form width */
      margin-top: 6%;
    }
    .trego-app-footer-subscribe-button {
        width: 100%;
        padding-right: 0;
        margin-left: 5px;
        border-radius: 5px;
        padding: 7px 7px 7px 7px;
        font-size: 1.4rem;
        text-align: center;
      }
    
    .subscribe-form .input-field {
        font-size: 1.3rem;
        margin-bottom: 0;
        align-self: self-start;
        margin-left: -10px;
        margin-right: 2px;
        height: 30px;
      }
      .subscribe-form .input-field::placeholder {
        font-size: 1rem; /* Smaller font size */
        text-align: center;
        padding-top: 1px;
        padding-bottom: 7px;
      }
    }
  
@media (max-width: 770px) {
    .trego-app-footer-content h3 {
        font-size: 1.5rem; /* Smaller font size */
      }

    .trego-app-footer {
      margin-bottom: -50px;
    }
    .trego-app-footer img {
      width: 100%; /* Full-width image */
      position: relative; /* Position for content overlay */
    }
    .subscribe-form .input-field {
        font-size: 0.6rem;
        margin-bottom: 0;
        align-self: self-start;
        margin-top: -15px;
        margin-left: -10px;
        margin-right: 2px;
        height: 30px;
      }



      .subscribe-form .input-field::placeholder {
        font-size: 0.6rem; /* Smaller font size */
        text-align: center;
        border: 1px solid rgb(104, 104, 104);
        border-radius: 4px;
        width: 100%;
        height: 20px;
      }
      .trego-app-footer-subscribe-button {
        width: 100%;
        padding-right: 0;
        margin-left: 5px;
        border-radius: 5px;
        padding: 7px 7px;
        font-size: 1.4rem;
        text-align: center;
      }
    
      .subscribe-form .input-field {
        font-size: 1.3rem;
        margin-bottom: 0;
        align-self: self-start;
        margin-top: 10px;
        margin-left: -10px;
        margin-right: 2px;
        height: 30px;
      }
      .subscribe-form .input-field::placeholder {
        font-size: 1rem; /* Smaller font size */
        text-align: center;
        padding-top: 1px;
        padding-bottom: 7px;
      }
}
@media (max-width: 650px){
    .Faqs-section{
        margin-bottom: 50px;
    }
    .trego-app-footer-content h3 {
        font-size: 1rem; /* Smaller font size */
      }
      .subscribe-form {
        max-width: 150px; /* Narrower form */
        margin-top: 2%;
      }
      .subscribe-form .input-field::placeholder {
          font-size: 1rem; /* Smaller font size */
          padding-bottom: 0px;
        }
      .trego-app-footer-subscribe-button {
        /* padding: 15px 25px; */
        width: 150px;
        font-size: 1rem;
      }
      .subscribe-form .input-field {
        font-size: 0.6rem;
        margin-bottom: 0;
        align-self: self-start;
        margin-top: -15px;
        margin-left: -10px;
        margin-right: 2px;
        height: 30px;
      }



      .subscribe-form .input-field::placeholder {
        font-size: 0.6rem; /* Smaller font size */
        text-align: center;
        border: 1px solid rgb(104, 104, 104);
        border-radius: 4px;
        width: 100%;
        height: 20px;
      }
      .trego-app-footer-subscribe-button {
        width: 100%;
        padding-right: 0;
        margin-left: 5px;
        border-radius: 5px;
        padding: 7px 7px;
        font-size: 0.6rem;
        text-align: center;
      }



.faq-container1{
    padding-left: 0;
    width: 100%;
    padding-right: 0;
}


.faq-content1{
    width: 80%;
    margin-left: 10%;
}

.faq-title1{
    font-size: 5vw;
}
}
.faq-item1 {
    border-bottom: 1px solid white;
    padding: 15px 0;
    cursor: pointer;
    width: 100%;
    background-color: transparent;
    transition: background-color 0.5s ease; /* Slow transition for background color */
}


@media (max-width: 520px) {
    .Faqs-section{
        margin-bottom: 50px;
    }


    .trego-app-footer img {
      width: 100%; /* Full-width image */
      height: 150px;
      position: relative; /* Position for content overlay */
    }
    .input-field {
      margin-top: 10%;
  }
    .trego-app-footer-icon img{
      padding-top: 80px;
      width: 8%;
      margin-bottom: -110px;
  }
      .footer-content h3 {
        font-size: 0.9rem; /* Smaller font size */
      }
      .subscribe-form {
        height: 50px;
        text-align: center;
        display: flex;
        flex-direction: row;
        border: transparent;
      }

      .subscribe-form .input-field {
        font-size: 0.6rem;
        margin-bottom: 0;
        align-self: self-start;
        margin-top: -15px;
        margin-left: -10px;
        margin-right: 2px;
        height: 30px;
      }

      .subscribe-form .input-field::placeholder {
          font-size: 0.6rem; /* Smaller font size */
          text-align: center;
          border: 1px solid rgb(104, 104, 104);
          border-radius: 4px;
          width: 100%;
          height: 20px;
        }
      .trego-app-footer-subscribe-button {
        width: 100%;
        padding-right: 0;
        margin-left: 5px;
        border-radius: 5px;
        padding: 7px 7px;
        font-size: 0.6rem;
        text-align: center;
      }
      .trego-app-footer-content{
        margin-top: 5%;
        margin-bottom: 2%;
      }
      .trego-app-footer-content h3 {
        font-size: 0.9rem; /* Smaller font size */
      }
    }

.faq-question1 {
    display: flex;
    align-items: center;
    text-align: start;
    font-size: 1.3vw;
    font-weight: 400;
    padding: 10px 0;
    color: white;
}

@media (max-width: 650px){

    .faq-question1{
        font-size: 3.3vw;
    }
    .faq-answer-content1{
        font-size: 2.8vw !important;
        width: 85%;
    }
}

.faq-question1 .arrow {
    margin-right: 20px;
    transition: transform 0.6s ease; /* Slower rotation */
    font-size: 1.25rem;
    color: white;
}

.faq-question1 .arrow.open {
    transform: rotate(90deg);
}

.faq-answer1 {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.8s ease-in-out, opacity 0.8s ease; /* Slower transition */
    opacity: 0;
}

.faq-answer1.open {
    max-height: 1000px; /* Adjust this value based on your content */
    opacity: 1;
}

.faq-answer-content1 {
    font-size: 1vw;
    font-weight: 100;
    text-align: start;
    color: rgb(255, 255, 255);
    margin-top: 10px;
    margin-left: 44px;
    transform: translateY(-10px);
    transition: transform 0.8s ease, opacity 0.8s ease; /* Slower transition */
    opacity: 0;
}



.faq-answer1.open .faq-answer-content1 {
    transform: translateY(0);
    opacity: 1;
}

/* Add a slow ripple effect animation */
@keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
}

.faq-item1:active {
    animation: ripple 1s linear;
}

.input-field.error {
  border-color: #ff4d4f;
  outline: none;
}

.error-message {
  color: #ff4d4f;
  font-size: 14px;
  margin-top: 5px;
}
