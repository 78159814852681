.accommodation-container {
    background-color: #FFB737; /* Yellow background */
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative; /* Enable positioning for text overlay */
  }
  
  .title-section {
    text-align: left; /* Align title to the left */
    margin-bottom: -80px; /* Space between title and content */
    padding-top: 6%;
    width: 25%;
    padding-left: 12%;
  }
  
  .title-section h2 {
    font-size: 2.5rem;
    color: white;
    margin: 0; /* Remove any extra margins */
  }

  @media (max-width: 1020px){
    .title-section {
      width: 60%;
      margin-bottom: 10px;
    }


  }

  @media (max-width: 650px){
    .title-section {
      width: 60%;
      margin-bottom: 10px;
    }

    .title-section h2{
      font-size: 1.5rem;
    }

  }

  @media (max-width: 400px){
    .title-section {
      width: 60%;
      margin-bottom: 10px;
    }

  }
  
  .content-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto; /* Center the content */
    padding-left: 50px;
    position: relative;
  }
  
  .center-image {
    flex: 1;
    display: flex;
    justify-content: center;
    position: relative; /* To allow text overlay */
    bottom: 0; /* Aligns the image to the bottom of the section */

  }
  
  .center-image img {
    width: 170%; /* Full width of the container */
    filter: brightness(0.8); /* Darkens the image slightly to improve text contrast */
    position: relative;
    bottom: 0; /* Aligns the image to the bottom of the section */

    
  }
  @media (max-width: 650px){
    .center-image img {
      width: 170% !important;
      padding-left: 20%;
      padding-right: 10%;
    }

  }

  @media (max-width: 1020px){
    .center-image img {
      padding-left: 40%;
      padding-right: 10%;
      width: 100%;
    }


  }

  .left-content, .right-content {
    flex: 1;
  }
  
  .icon-text-block {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center elements horizontally */
    margin-bottom: 20px;
  }
  
  .icon {
    width: 40px; /* Adjust the icon size */
    height: auto;
    margin-bottom: 15px; /* Space between image and text */
    align-self: flex-start;
  }

  @media (max-width: 650px){
    .icon {
      width: 30px;
    }

  }

  
  .left-content h3 , .right-content h3{
    font-size: 1.3rem;
    text-align: start; /* Center text */
    color: white;
    margin: 0 0 10px 0;
    width: 70%;
  }

  @media (max-width: 650px){
    .left-content h3 , .right-content h3{
      font-size: 1rem;
      position: absolute;
    }

    .left-content .icon-text-block{
      width: 150%;

    }

    .right-content .icon-text-block{
      width: 150%;
      margin-left: -40%;
      margin-bottom: -10%;
    }

  }
  
  .left-content p , .right-content p{
    font-size: 0.8rem;
    width: 65%; /* Full width to match the container */
    text-align: start; /* Center text */
    color: white;
    line-height: 1.6;
  }

  @media (max-width: 650px){
    .left-content h3 , .right-content h3{
      font-size: 1rem;
      padding-bottom: 10px;
      width: 50%;
    }

    .left-content p , .right-content p{
      font-size: 0.6rem;
      padding-top: 15%;
    }

  }

  .right-content  p {
    padding-bottom: 20%;
    }

.left-content{
    padding-right: 10%;
}
  .left-content img{
    padding-top: 20%;
  }

  .right-content {
    padding-left: 10%;
  }
  

  