.delete-account-form {
  padding: 2.5rem;
  border-radius: 8px;
  background-color: #f8f9fa;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  max-width: 600px;
  margin: 0 auto;
  border: 1px solid #e0e0e0;
}

.delete-account-form h3 {
  font-size: 1.5rem;
  margin-bottom: 1.75rem;
  color: #333;
  font-weight: 600;
  text-align: center;
  position: relative;
  padding-bottom: 0.75rem;
}

.delete-account-form h3::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background-color: #dc3545;
  border-radius: 3px;
}

.delete-account-form-group {
  margin-bottom: 1.75rem;
}

.delete-account-form-group label {
  display: block;
  margin-bottom: 0.75rem;
  font-weight: 500;
  font-size: 0.95rem;
  color: #333;
}

.delete-account-form-input {
  width: 100%;
  padding: 0.85rem 1.25rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 1rem;
  transition: all 0.2s ease;
  color: #333;
  background-color: #fff;
  font-family: inherit;
}

.delete-account-form-input:focus {
  outline: none;
  border-color: #dc3545;
  box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.25);
}

.delete-account-form-input::placeholder {
  color: #666;
  opacity: 1;
}

select.delete-account-form-input {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23666'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1rem;
  padding-right: 2.5rem;
}

.delete-account-form-input:disabled {
  background-color: #e9ecef;
  color: #666;
  cursor: not-allowed;
}

textarea.delete-account-form-input {
  min-height: 140px;
  resize: vertical;
  line-height: 1.5;
}

.delete-account-checkbox-group {
  display: flex;
  align-items: flex-start;
  gap: 0.85rem;
  margin: 2.25rem 0;
  padding: 1.25rem;
  background-color: rgba(220, 53, 69, 0.05);
  border-radius: 4px;
  border-left: 3px solid #dc3545;
}

.delete-account-custom-checkbox {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-top: 0.15rem;
  cursor: pointer;
  flex-shrink: 0;
  transition: all 0.2s ease;
  position: relative;
}

.delete-account-custom-checkbox:checked {
  background-color: #dc3545;
  border-color: #dc3545;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='white'%3E%3Cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 14px;
}

.delete-account-custom-checkbox:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.3);
}

.delete-account-checkbox-group label {
  font-weight: normal;
  font-size: 0.95rem;
  line-height: 1.6;
  color: #333;
  flex: 1;
}

.delete-account-btn {
  width: 100%;
  padding: 1.1rem;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1.05rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.85rem;
  letter-spacing: 0.5px;
}

.delete-account-btn:hover:not(:disabled) {
  background-color: #c82333;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(220, 53, 69, 0.3);
}

.delete-account-btn:active:not(:disabled) {
  transform: translateY(0);
}

.delete-account-btn:disabled {
  background-color: #e9ecef;
  color: #666;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.delete-account-btn.disabled:not(:disabled) {
  background-color: #ffcdd2;
  color: #c62828;
  cursor: not-allowed;
}

.delete-account-spinner {
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: delete-account-spin 1s ease-in-out infinite;
}

@keyframes delete-account-spin {
  to { transform: rotate(360deg); }
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .delete-account-form {
      padding: 1.75rem;
  }
  
  .delete-account-form h3 {
      font-size: 1.3rem;
  }
  
  .delete-account-checkbox-group {
      margin: 1.75rem 0;
      padding: 1rem;
  }
}

/* Add these new styles to your existing CSS */
.delete-account-otp-section {
  display: flex;
  gap: 0.75rem;
  margin-top: 0.5rem;
}

.delete-account-otp-input {
  flex: 1;
  padding: 0.85rem 1.25rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 1rem;
  transition: all 0.2s ease;
  color: #333;
  background-color: #fff;
}

.delete-account-send-code-btn {
  padding: 0.5rem 1rem;
  background-color: #4a6bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.delete-account-send-code-btn:hover:not(:disabled) {
  background-color: #3a5bef;
}

.delete-account-send-code-btn:disabled {
  background-color: #e9ecef;
  color: #666;
  cursor: not-allowed;
}

.delete-account-timer {
  font-size: 0.85rem;
  color: #dc3545;
  margin-top: 0.5rem;
  font-weight: 500;
}

.delete-account-auth-method {
  margin-bottom: 1.5rem;
  font-size: 0.9rem;
  color: #666;
}

.delete-account-auth-method strong {
  color: #333;
}
/* Add these new styles to your existing CSS */
.delete-account-auth-type {
  margin-bottom: 1.5rem;
}

.delete-account-auth-options {
  display: flex;
  gap: 1rem;
  margin-top: 0.75rem;
}

.delete-account-auth-option {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: center;
}

.delete-account-auth-option:hover {
  border-color: #4a6bff;
}

.delete-account-auth-option.selected {
  border-color: #4a6bff;
  background-color: rgba(74, 107, 255, 0.05);
}

.delete-account-auth-option input[type="radio"] {
  display: none;
}

.delete-account-auth-icon {
  width: 24px;
  height: 24px;
  margin-bottom: 0.5rem;
}

/* Existing OTP styles (from previous example) */
.delete-account-otp-section {
  display: flex;
  gap: 0.75rem;
  margin-top: 0.5rem;
}

.delete-account-otp-input {
  flex: 1;
  padding: 0.85rem 1.25rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 1rem;
  transition: all 0.2s ease;
  color: #333;
  background-color: #fff;
}

.delete-account-send-code-btn {
  padding: 0.5rem 1rem;
  background-color: #4a6bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.delete-account-send-code-btn:hover:not(:disabled) {
  background-color: #3a5bef;
}

.delete-account-send-code-btn:disabled {
  background-color: #e9ecef;
  color: #666;
  cursor: not-allowed;
}

.delete-account-timer {
  font-size: 0.85rem;
  color: #dc3545;
  margin-top: 0.5rem;
  font-weight: 500;
}