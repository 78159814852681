.delete-account-detail-section {
    margin-bottom: 2rem;
  }
  
  .delete-account-detail-section h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    color: var(--text-color);
    position: relative;
    padding-left: 1.5rem;
  }
  
  .delete-account-detail-section h3::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    background-color: var(--danger-color);
    border-radius: 50%;
  }
  
  .delete-account-alternatives {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
    margin-top: 1.5rem;
  }
  
  .delete-account-alternative-option {
    display: flex;
    gap: 1rem;
    padding: 1.25rem;
    background-color: var(--bg-light);
    border-radius: var(--radius-md);
    transition: var(--transition);
  }
  
  .delete-account-alternative-option:hover {
    transform: translateY(-3px);
    box-shadow: var(--shadow-sm);
  }
  
  .delete-account-alternative-option svg {
    width: 24px;
    height: 24px;
    color: var(--primary-color);
    flex-shrink: 0;
    margin-top: 0.25rem;
  }
  
  .delete-account-alternative-option h4 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: var(--text-color);
  }
  
  .delete-account-alternative-option p {
    font-size: 0.9rem;
    color: var(--text-light);
    margin-bottom: 0.75rem;
  }
  
  .delete-account-alt-link {
    color: var(--primary-color);
    font-size: 0.9rem;
    font-weight: 500;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
  }
  
  .delete-account-alt-link:hover {
    text-decoration: underline;
  }
  
  .delete-account-alt-link::after {
    content: '→';
    margin-left: 0.25rem;
    transition: var(--transition);
  }
  
  .delete-account-alt-link:hover::after {
    transform: translateX(3px);
  }