
/* Purple Intro Section */
/* Default (large screens, desktop) */
.trego-container{
  overflow-y: hidden;
  padding-bottom: 40px;
}
.intro-section {
  background-color: #bab4f7;
  width: 100%;
  height: 400px;
  position: relative;
  text-align: center;
  align-items: center;
  display: flex;
}

.intro-section h1 {
  color: #fff;
  font-size: 3.1rem;
  margin-bottom: 20px;
  text-align: start;
  width: 50%;
  padding-left: 6%;
  padding-top: 3%;
}

/* Medium screens (laptops, tablets in landscape mode) */
@media (max-width: 1024px) {
  .intro-section {
      height: 350px;
  }
  .intro-section h1 {
      font-size: 2.5rem;
      width: 50%;
      padding-left: 5%;
  }
}

/* Small screens (tablets in portrait mode) */
@media (max-width: 768px) {
  .intro-section {
      height: 300px;
  }
  .intro-section h1 {
    padding-top: 10%;
      font-size: 2.3rem;
      width: 50%;
      height: 50%;
      padding-left: 4%;
  }
}

/* Extra small screens (large smartphones) */
@media (max-width: 576px) {
  .intro-section {
    padding-top: 130px !important;
  }
  .intro-section h1 {
      padding-top: 20%;
      font-size: 4vw;
      width: 40% !important;
      padding-left: 0px !important;
      padding-right: 100px !important;
  }
}

/* Very small screens (small smartphones) */
@media (max-width: 360px) {
  .intro-section {
      height: 250px;
  }
  .intro-section h1 {
      font-size: 1rem;
      width: 75%;
      padding-left: 2%;
  }
}

/* Media query for screens with max-width of 650px */
@media (max-width: 650px) {

  .trego-container{
    padding-bottom: 0 !important;
  }

  .intro-section {
    flex-direction: row-reverse; /* Swap the order: image first, then h1 */
    justify-content: space-between;
    align-items: center;
    height: auto; /* Allow the section to adapt to content height */
    padding: 20px; /* Add some padding for spacing */
    padding-top: 120px;
  }

  .intro-section h1 {
    width: 40%; /* Adjust the width of h1 */
    text-align: left; /* Align text to the left */
    padding: 0 10px; /* Add space between the h1 and image */
    font-size: 4vw;
    padding-right: 20px !important;
  }

  .girl-image {
    position: static; /* Remove absolute positioning */
    width: 45% !important; /* Adjust the image size */
    right: 45% !important;
    margin: 0; /* Ensure no extra margins */
  }
}

.girl-image {
  position: absolute;
  right: 18%;
  bottom: 0; /* Aligns the image to the bottom of the section */
  width: 27%;
  transform: translateY(43%); /* Shifts the image up by half of its height to center it vertically at the bottom */
  z-index: 2; /* Ensures the girl image appears above the other content */
}





@media (max-width: 650px) {
  .main-content{
    padding-top: 90% !important;
  }

  .features-header{
    margin-left: 10px !important;
    width: 98% !important;
    font-size: 2rem;
    text-align: center !important;
  }
  .features-list {
    margin-left: 25% !important;
}

.features-list img {
  margin-left: 10%;
  padding-bottom: 10px;
}

.features-list li {
    flex-direction: column; /* Stack image, h3, and p vertically */
    align-items: flex-start; /* Align items to the left */
}

.features-text {
    width: 100%; /* Ensure full width */
    text-align: center;
}

.features-text h3 {
    margin-bottom: 5px; /* Add space between h3 and p */
}

  .text-content{
    width: 100% !important;
  }
  .image-content {
    position: absolute;
    right: 24% !important;
    transform: translateY(1%);
    top: 50px;
    width: 90% !important;
  }

  .feature-box{
    width: 40vw !important; /* Width based on the viewport width */
    height: 14vw !important; /* Adjust height based on content */
    margin-right: 570px !important;
  }

  .feature-text h3 {
    margin-bottom: 5px !important;
    margin-top: -20px;
    font-size: 3vw !important;
  }
  
  .feature-text p {
    margin: 0;
    font-size: 2vw !important;
  }

}
/* Main Content Section */
.main-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8%;
    position: relative;
    z-index: 1;
}



.text-content {
    width: 50%;
    color: black;
}
  
  .features-header {
    font-weight: 300;
    text-align: start;
    width: 80%;
    font-size: 3rem;
    margin-bottom: 20px;
    margin-left: 90px;
  }
  .features-list {
    margin-left: 13%;
    list-style: none;
    padding: 0;
    text-align: start;
    width: 50%;
  }
  
  .features-list li {
    display: flex; /* Flexbox to position the image and text side by side */
    align-items: center; /* Vertically center align the image and text */
    margin-bottom: 50px;
  }
  
  .features-list img {
    width: 50px; /* Adjust as needed */
    height: auto;
    margin-right: 30px; /* Add space between the image and text */
  }
  
  .features-list h3 {
    margin: 0; /* Remove default margin */
    margin-bottom: 10px;
    font-size: 1.2rem; /* Adjust font size */
  }
  
  .features-list p {
    margin: 0;
    font-size: 1rem;
  }
  
  /* To group the h3 and p together */

  .store-buttons img {
    width: 150px;
    margin-right: 10px;
  }
  
  .image-content {
    position: absolute;
    right: 10%;
    transform: translateY(1%);
    top: 50px;
    width: 50%;
  }
  
  .image-content img {
    width: 45%;
    transform: translateY(1%);
    margin: 0 auto;
  }


  @media (max-width: 1024px) {

  }
  
  /* Small screens (tablets in portrait mode) */
  @media (max-width: 768px) {

  }
  
  /* Extra small screens (large smartphones) */
  @media (max-width: 576px) {

  }
  
  /* Very small screens (small smartphones) */
  @media (max-width: 360px) {

  }
  
  .app-features {
    position: absolute;
    right: -630px;
    top: 13%;
  }
  
  .feature-box {
    background-color: white;
    color: black;
    margin-bottom: 20px;
    width: 20vw; /* Width based on the viewport width */
    height: 6vw; /* Adjust height based on content */
    padding: 2vw; /* Padding scales with the viewport */
    border: 2px solid black;
    margin-right: 560px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center; /* Align content within each box */
}

@media (max-width: 1024px) {

}

/* Small screens (tablets in portrait mode) */
@media (max-width: 768px) {
  .feature-box {
    margin-right: 580px;
  }
}

/* Extra small screens (large smartphones) */
@media (max-width: 576px) {

}

/* Very small screens (small smartphones) */
@media (max-width: 360px) {

}



.feature-box img{
    width: 20%; /* Adjust width according to your design */
    height: auto;
}

.feature-text {
    margin-left: 10px; /* Add space between the image and text */
    text-align: start;
    width: 70%;
  }

  .feature-text h3 {
    margin-bottom: 10px;
    margin-top: -20px;
    font-size: 1.2vw;
  }
  
  .feature-text p {
    margin: 0;
    font-size: 1vw;
  }
  



  @media (max-width: 768px) {
    .feature-text h3 {
      margin-bottom: 5px;
      margin-top: 0;
    }

  }
  
  /* Extra small screens (large smartphones) */
  @media (max-width: 576px) {

  }
  
  /* Very small screens (small smartphones) */
  @media (max-width: 360px) {

  }