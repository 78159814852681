.delete-account-header {
    padding: 2rem;
    text-align: center;
    border-bottom: 1px solid var(--border-color);
    position: relative;
  }
  
  .delete-account-header h1 {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
    color: var(--danger-color);
  }
  
  .delete-account-subtitle {
    color: var(--text-light);
    font-size: 1rem;
  }
  
  .delete-account-warning-icon {
    width: 60px;
    height: 60px;
    margin: 0 auto 1rem;
    background-color: #fff6f6;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .delete-account-warning-icon svg {
    width: 30px;
    height: 30px;
    color: var(--danger-color);
  }