@media (max-width: 768px) {
    .delete-account-container {
      padding: 1rem 0.5rem;
    }
    
    .delete-account-header,
    .delete-account-deletion-details,
    .delete-account-form {
      padding: 1.5rem;
    }
    
    .delete-account-warning-banner {
      margin: 0 1.5rem;
      transform: translateY(-0.5rem);
    }
    
    .delete-account-alternatives {
      grid-template-columns: 1fr;
    }
    
    .delete-account-confirmation-actions {
      flex-direction: column;
    }
    
    .delete-account-cancel-btn,
    .delete-account-confirm-delete-btn {
      width: 100%;
    }
    
    .delete-account-impact-section {
      gap: 1rem;
    }
    
    .delete-account-impact-header {
      padding: 1rem;
    }
    
    .delete-account-impact-list {
      padding: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .delete-account-header h1 {
      font-size: 1.5rem;
    }
    
    .delete-account-form h3 {
      font-size: 1.2rem;
    }
    
    .delete-account-warning-banner {
      flex-direction: column;
      align-items: flex-start;
      padding: 1rem;
    }
    
    .delete-account-alternative-option {
      flex-direction: column;
    }
  }