.delete-account-confirmation-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 1rem;
  }
  
  .delete-account-confirmation-dialog {
    background: white;
    border-radius: var(--radius-md);
    max-width: 450px;
    width: 100%;
    overflow: hidden;
    box-shadow: var(--shadow-lg);
    animation: delete-account-fadeIn 0.3s ease-out;
  }
  
  @keyframes delete-account-fadeIn {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  .delete-account-confirmation-header {
    padding: 1.5rem;
    background-color: #fff6f6;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    border-bottom: 1px solid var(--border-color);
  }
  
  .delete-account-confirmation-header svg {
    width: 24px;
    height: 24px;
    color: var(--danger-color);
  }
  
  .delete-account-confirmation-dialog h3 {
    font-size: 1.2rem;
    color: var(--danger-color);
  }
  
  .delete-account-confirmation-dialog p {
    padding: 1.5rem;
    line-height: 1.6;
  }
  
  .delete-account-confirmation-actions {
    display: flex;
    padding: 0 1.5rem 1.5rem;
    gap: 1rem;
  }
  
  .delete-account-cancel-btn {
    flex: 1;
    padding: 0.75rem;
    background-color: white;
    border: 1px solid var(--border-color);
    border-radius: var(--radius-sm);
    font-weight: 500;
    cursor: pointer;
    transition: var(--transition);
  }
  
  .delete-account-cancel-btn:hover {
    background-color: #f8f9fa;
  }
  
  .delete-account-confirm-delete-btn {
    flex: 1;
    padding: 0.75rem;
    background-color: var(--danger-color);
    color: white;
    border: none;
    border-radius: var(--radius-sm);
    font-weight: 600;
    cursor: pointer;
    transition: var(--transition);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
  }
  
  .delete-account-confirm-delete-btn:hover {
    background-color: var(--danger-hover);
  }
  
  .delete-account-confirm-delete-btn:disabled {
    background-color: #bdc3c7;
    cursor: not-allowed;
  }