/* General Container */
.trego-container {
    color: white;
    /* padding-top: 80px; */
    margin: 0;
    padding-bottom: 100px;
    overflow-x: hidden;
    /* min-height: 100vh; */
    overflow-y: auto;  
  }
