.Home {
  background-color: #181818;
  color: white;
  padding-top: 80px;
  margin: 0;
  padding-bottom: 100px;
  overflow-x: hidden;
  min-height: 100vh;
  overflow-y: auto;  
}

.home__container {
  background-color: black;
}


.content {
  /* visibility: visible; */
  opacity: 1;
  position: relative;
}
