/* Base Styles for How to Become a Partner Section */
.how-to-partner-section {
    padding: 80px 0;
    text-align: center;
}

.how-to-partner-section h2 {
    font-size: 32px;
    color: white;
    margin-bottom: 60px;
}

.step-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-left: 50px;
}

.step-item {
    display: flex;
    align-items: center;
    text-align: left;
    position: relative;
    margin-bottom: 50px;
}

/* Adjust the second step (image on right) */
.step-item:nth-child(2) {
    flex-direction: row-reverse;
    align-self: flex-end;
    margin-top: -200px;
}

.step-item:nth-child(3) {
    margin-top: -100px;
}

/* Images */
.main-image1, .main-image2, .main-image3 {
    position: relative;
    z-index: 1;
}

.main-image1 {
    /* width: 550px; */
    width: 30%;
  }

.main-image2 {
    /* width: 650px; */
    width: 25%;
    z-index: 1;
}

.main-image3 {
    width: 30%;
}

/* Number positioning and styling */
.step-number {
    font-size: 96px;
    font-weight: 800;
    color: white;
    margin-right: 20px;
    margin-bottom: 50px;
}

.step-item:nth-child(2) .step-number {
    margin-right: -10px;
}

.step-content {
    display: flex;
    align-items: center;
    color: white;
    position: relative;
    z-index: 2;
}

.step-item:nth-child(2) .step-content {
    position: absolute;
    display: flex;
    right: 300px;
    top: 25%;
    width: 28%;
}

.step-item:nth-child(1) .step-content {
    position: absolute;
    transform: translateX(110%);
    top: 20%;
    width: 26%;
}

.step-item:nth-child(3) .step-content {
    position: absolute;
    top: 30%;
    transform: translateX(100%);
    width: 30%;
}

.step-text {
    max-width: 500px;
}

.step-item:nth-child(2) .step-text {
    padding: 20px;
    border-radius: 10px;
    color: white;
}

.step-text h3 {
    font-size: 24px;
    color: white;
    margin-bottom: 10px;
}

.step-text p {
    font-size: 14px;
    color: #ccc;
    line-height: 1.6;
    width: 100%;
}

@media (max-width: 1256px) {
  .step-item:nth-child(2) {
    margin-top: -200px;
}

.step-item:nth-child(3) {
    margin-top: -100px;
}

.step-text h3 {
  font-size: 23px;
  color: white;
  margin-bottom: 10px;
}

.step-text p {
  font-size: 12px;
  color: #ccc;
  line-height: 1.6;
  width: 100%;
}

.step-item:nth-child(2) .step-content {
  position: absolute;
  display: flex;
  right: 230px;
  top: 25%;
  width: 28%;
}

.step-item:nth-child(1) .step-content {
  position: absolute;
  top: 20%;
  width: 26%;
}

.step-item:nth-child(3) .step-content {
  position: absolute;
  top: 30%;
  transform: translateX(100%);
  width: 30%;
}

}

@media (max-width: 1065px){
  .step-item:nth-child(2) {
    margin-top: -150px;
}

.step-item:nth-child(3) {
    margin-top: -50px;
}

.step-item:nth-child(1) .step-content {
  position: absolute;
  transform: translateX(100%);
  top: 30%;
  width: 30%;
}

.step-item:nth-child(3) .step-content {
  position: absolute;
  right: 20px;
  top: 30%;
  transform: translateX(-70%);
  width: 40%;
}

.step-text h3 {
  font-size: 18px;
  color: white;
  margin-bottom: 10px;
}

.step-text p {
  font-size: 9px;
  color: #ccc;
  line-height: 1.6;
  width: 100%;
}
}

@media (max-width: 925px){

  .step-item:nth-child(2) {
    flex-direction: row-reverse;
    align-self: flex-end;
    margin-top: -100px;
}

.step-item:nth-child(3) {
    margin-top: -130px;
}

.main-image1 {
  /* width: 550px; */
  width: 30%;
}

.main-image2 {
  /* width: 650px; */
  width: 30%;
  z-index: 1;
}

.main-image3 {
  width: 30%;
}

.step-item:nth-child(1) .step-content {
  position: absolute;
  top: 10%;
  transform: translateX(110%);
  width: 25%;
}

.step-item:nth-child(2) .step-content {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-20%);
  width: 30%;
}

.step-item:nth-child(3) .step-content {
  position: absolute;
  top: 30%;
  transform: translateX(-90%);
  width: 35%;
}

.step-text h3 {
  font-size: 18px;
  color: white;
  margin-bottom: 10px;
}

.step-text p {
  font-size: 9px;
  color: #ccc;
  line-height: 1.6;
  width: 100%;
}

.step-number {
  font-size: 60px;
  font-weight: 800;
  color: white;
  margin-right: 20px;
  margin-bottom: 30px;
}

}

@media (max-width: 768px) {

  .step-item:nth-child(3) {
    margin-top: -40px;
}

.step-item:nth-child(1),.step-item:nth-child(3) {

  margin-left: -40px;
}

.step-content{
  position: relative;
  align-items: start;
  text-align: start;
  display: flex;
  flex-direction: row;
}

.step-item:nth-child(1),
.step-item:nth-child(3)
{
  flex-direction: row;
}

.step-number{
  margin-bottom: -100px;
  font-size: 40px;
}

.step-item:nth-child(1) .step-content {
  position: absolute;
  top: 10%;
  transform: translateX(90%);
  width: 30%;
}

.step-item:nth-child(2) .step-content {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translateX(-33%);
  width: 40%;
}

.step-item:nth-child(3) .step-content {
  position: absolute;
  top: 30%;
  transform: translateX(-65%);
  width: 40%;
}

.step-item:nth-child(1) .step-number {
  margin-right: 10px;
  margin-top: 10px;
}

.step-item:nth-child(2) .step-number {
  margin-right: -10px;
  margin-top: 40px;
}

.step-item:nth-child(3) .step-number {
  margin-right: 10px;
  margin-top: 10px;
}

}

@media (max-width: 600px){

  .step-item:nth-child(1) .step-content {
    position: absolute;
    top: 10%;
    transform: translateX(40%);
    width: 70%;
  }
  
  .step-item:nth-child(2) .step-content {
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translateX(-60%);
    width: 70%;
  }
  
  .step-item:nth-child(3) .step-content {
    position: absolute;
    top: 10%;
    transform: translateX(-5%);
    width: 60%;
  }

  .step-item:nth-child(2) {
    margin-top: 0px;
}

.step-item:nth-child(3) {
    margin-top: 30px;
}

.step-item:nth-child(3) .step-number{
  margin-top: 30px;
}


}

@media (max-width: 400px){

  .step-number {
    font-size: 20px;
    font-weight: 800;
    color: white;
    margin-right: 20px;
    margin-bottom: 30px;
  }

}

