.popup {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
    opacity: 0;
    transform: translateY(-10px);
    animation: fadeIn 0.5s forwards, fadeOut 0.5s 2.5s forwards;
    display: flex;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    color: white;
    font-size: 16px;
    width: 300px;
  }
  
  .success-popup {
    background-color: #4caf50; /* Green background */
  }
  
  .failure-popup {
    background-color: #f44336; /* Red background */
  }
  .popup-content p{
  color: white;
  }
  .popup-content {
    
    display: flex;
    align-items: center;
  }
  
  .popup-icon {
    font-size: 20px;
    margin-right: 10px;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(-10px);
    }
  }
  