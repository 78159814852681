/* Container for Contact Us Page */
.contact-us-container2 {
  max-width: 100%;
  background-color: white;
}

.contact-us-content2 {
  width: 70%;
  margin: 0 auto;
  padding: 20px;
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;

}

.contact-us-container2 h2 {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #181818;
  text-align: start;
}

.contact-us-container2 p {
  color: #666;
  margin-bottom: 30px;
  text-align: start;
  width: 100%;
}

/* Form Styling */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: start;
  box-sizing: border-box;
}

.form-columns {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}

.form-column {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%; /* Adjust width to create two columns */
}

.form-row {
  display: flex;
  gap: 15px;
  width: 100%;
}

/* Input fields and textareas */
.contact-us-container2 input,
.contact-us-container2 select,
.contact-us-container2 textarea {
  font-family: 'Plus Jakarta Sans', sans-serif;
  padding: 10px;
  font-size: 1rem;
  color: #181818;
  width: 100%;
  border: 1px solid #3d3d3d;
  border-radius: 5px;
  box-sizing: border-box;
}

.contact-us-container2 input::placeholder,
.contact-us-container2 textarea::placeholder {
  color: black;
}

.contact-us-container2 textarea {
  height: 70%;
  width: 100%;
  resize: none;
}

.send-request-btn {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 30%;
  background-color: #0032CF;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.send-request-btn:hover {
  background-color: #00218e;
}

/* Responsive Design */
@media (max-width: 768px) {

.contact-us-container2 input,
.contact-us-container2 select,
.contact-us-container2 textarea {
  width: 150%;
  align-self: center;
}

.form-row {
  display: flex;
  gap: 15px;
  align-self: center;
}

  .contact-us-container2 {
    max-width: 100%;
    padding: 20px;
    padding-top: 80px;
    padding-bottom: 100px;
    text-align: center;
  }

  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    align-items: center; /* Center form elements */
  }

  .contact-us-container2 h2 {
    font-size: 1.5rem;
    text-align: center;
  }

  .contact-us-container2 p {
    font-size: 0.9rem;
    text-align: center;
    width: 100%; /* Expand text to full width on small screens */
  }

  .form-columns {
      flex-direction: column;
      align-items: center; /* Center columns */
      width: 100%;
  }

  .form-columns input, .form-columns select, .form-columns textarea {
      width: 100%;
      text-align: center; /* Center text inside inputs and textarea */
  }

  .send-request-btn {
      width: 50%; /* Make button wider on small screens */
      align-self: center;
  }
}

@media (max-width: 480px) {
  .contact-us-container2 h2 {
      font-size: 1.5rem;
      text-align: center;
  }

  .contact-us-container2 p {
      font-size: 0.9rem;
      text-align: center;
      width: 100%;
  }

  input, select, textarea {
      font-size: 0.9rem;
  }

  .send-request-btn {
      font-size: 0.9rem;
      padding: 8px 16px;
      width: 80%; /* Center the button and make it even wider */
  }
}
