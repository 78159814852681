/* Animations for Trego App */

/* Fade in and slide up animation for the intro section */
@keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .intro-section h1 {
    animation: fadeInUp 1s ease-out;
  }
  
  /* Float animation for the girl image */
  /* @keyframes float {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0px);
    }
  } */
  
  .girl-image {
    animation: float 3s ease-in-out infinite;
  }
  
  /* Staggered fade in for feature list items */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .features-list li {
    opacity: 0;
    animation: fadeIn 0.5s ease-out forwards;
  }
  
  .features-list li:nth-child(1) {
    animation-delay: 0.2s;
  }
  
  .features-list li:nth-child(2) {
    animation-delay: 0.4s;
  }
  
  .features-list li:nth-child(3) {
    animation-delay: 0.6s;
  }
  
  /* Pulse animation for store buttons */
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .store-buttons img:hover {
    animation: pulse 0.5s ease-in-out;
  }
  
  /* Slide in animation for the phone image */
  @keyframes slideInRight {
    from {
      opacity: 0;
      transform: translateX(50px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .image-content img {
    animation: slideInRight 1s ease-out;
  }
  
  /* Rotate animation for feature icons */
  @keyframes rotateIcon {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .feature-icon:hover {
    animation: rotateIcon 1s linear;
  }
  
  /* Scale animation for feature boxes */
  @keyframes scaleUp {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.05);
    }
  }
  
  .feature-box {
    transition: transform 0.3s ease;
  }
  
  .feature-box:hover {
    animation: scaleUp 0.3s ease forwards;
  }