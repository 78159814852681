/* Grid container for the landing section */
.landing-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  padding: 50px;
  box-sizing: border-box;
}

/* Styling for both sections */
.landing-container .section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.landing-container .left {
  text-align: left;
}

.landing-container .right {
  text-align: left;
}

/* Make images responsive */
.landing-container img {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  display: block;
}

/* Headings */
.landing-container h2 {
  font-size: 3.5vw; /* Responsive heading size based on viewport width */
  margin-bottom: 20px;
  width: 90%;
  line-height: 1.2;
}

/* Paragraphs */
.landing-container p {
  font-size: 1.2vw; /* Fluid font size based on viewport */
  line-height: 1.6;
  width: 80%;
  font-weight: 300;
  color: rgb(197, 197, 197);
  margin-bottom: 10px;
}

/* Buttons */
.landing-container .btn {
  background-color: #0032CF;
  color: white;
  margin-top: 20px;
  padding: 12px 80px;
  font-size: 1.2vw;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: fit-content;
}

.landing-container .btn:hover {
  background-color: #0026a1;
}

/* Model container for 3D canvas */
.landing-container .model-container {
  width: 80%;
  height: 400px;
}

/* Media Queries for responsiveness */
@media (max-width: 1024px) {
  .landing-container {
    grid-template-columns: 1fr; /* Stack sections vertically on smaller screens */
    padding: 30px;
    gap: 30px;
  }

  .landing-container h2 {
    font-size: 2.8em; /* Adjust heading size */
  }

  .landing-container p {
    font-size: 1.0em;
    width: 100%;
  }

  .landing-container .btn {
    font-size: 1em;
    padding: 10px 20px;
  }

  .landing-container .model-container {
    height: 350px;
  }

  .model-container {
    order: 2; /* Ensure model is after text on small screens */
    padding-left: 100px;
  }
}

@media (max-width: 768px) {
  .landing-container {
    padding: 20px;
  }

  .landing-container h2 {
    font-size: 2.2em;
  }

  .landing-container p {
    font-size: 0.9em;
  }

  .landing-container .btn {
    font-size: 0.9em;
    padding: 10px 20px;
  }

  .landing-container .model-container {
    height: 300px;
  }

  .model-container {
    order: 2; /* Ensure model is after text on small screens */
    padding-left: 50px;

  }
}

@media (max-width: 480px) {
  .landing-container {
    padding: 15px;
  }

  .landing-container h2 {
    font-size: 1.8em;
    margin-bottom: 15px;
  }

  .landing-container p {
    font-size: 0.85em;
    line-height: 1.5;
  }

  .landing-container .btn {
    font-size: 0.8em;
    padding: 8px 16px;
  }

  .landing-container .model-container {
    height: 250px;
  }

    .model-container {
    height: 250px;
    order: 2; /* Ensure model is after text on small screens */
  }
}
