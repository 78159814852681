/* FAQs.css */
.faqs-section {
  padding: 60px 20px;
  background-color: #181818;
  text-align: center;
}

.FAQs {
  width: 10%;
}

.faqs-section h2 {
  font-size: 32px;
  color: white;
  margin-bottom: 20px;
}

.faqs-section p {
  color: #bbb;
  margin-bottom: 40px;
}

.faq-list {
  max-width: 75%;
  margin: 0 auto;
}

.faq-item {
  background-color: #252525;
  margin-bottom: 10px;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.5s, transform 0.5s; /* Slowed down from 0.3s */
}

.faq-item:hover {
  transform: translateY(-2px);
}

.faq-item.open {
  background-color: #333;
}

.faq-question {
  font-size: 18px;
  font-weight: 50;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-toggle {
  transition: transform 0.6s ease; /* Slowed down from 0.3s */
}

.faq-toggle.open {
  transform: rotate(45deg);
}

.faq-toggle svg {
  width: 24px;
  height: 24px;
  stroke: #bbb;
  transition: stroke 0.5s; /* Slowed down from 0.3s */
}

.faq-item.open .faq-toggle svg {
  stroke: #fff;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.8s ease, opacity 0.8s ease; /* Slowed down from 0.5s */
  opacity: 0;
}

.faq-answer.open {
  max-height: 1000px; /* Adjust this value based on your content */
  opacity: 1;
}

.faq-answer-content {
  margin-top: 15px;
  text-align: start;
  font-size: 16px;
  color: #ccc;
  line-height: 1.6;
  opacity: 0.4;
  transform: translateY(-10px);
  transition: transform 0.8s ease, opacity 0.8s ease; /* Slowed down from 0.5s */
}

.faq-answer.open .faq-answer-content {
  transform: translateY(0);
  opacity: 1;
}

@media (max-width: 750px) {
  .FAQs {
    width: 20%;
  }
}

/* Add a slower ripple effect animation */
@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
}

.faq-item:active {
  animation: ripple 1s linear; /* Slowed down from 0.6s */
}