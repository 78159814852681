/* ContactUs.css */

.contact-us-home-section {
    background-position: center;
    padding: 0px 20px;
    text-align: center;
    margin-top: 150px;
}

.contact-us-home-container {
    margin: 0 auto;
    width: 30%;
    padding: 40px;
    border-radius: 10px;
}

.contact-us-home-icon img{
    padding-top: 100px;
    width: 8%;
}

.contact-us-home-container h2 {
    text-align: start;
    font-size: 32px;
    color: white;
    margin-bottom: 10px;
}

.contact-us-home-container p {
    text-align: start;
    font-size: 16px;
    color: #ccc;
    margin-bottom: 30px;
}

.contact-us-home-form-landing {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box; /* Ensures padding and border are included in width */
}

.form-input-landing {
    border-radius: 5px;
    padding: 15px;
    background: transparent;
    font-size: 16px;
    color: white;
    margin-bottom: 10px;
    border: 1px solid white;
    width: 100%;
    box-sizing: border-box; /* Includes padding and border in width */
}

.form-input-group-landing {
    border-radius: 5px;
    font-size: 16px;
    color: white;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 0;
    width: 100%;
}

.form-input-group-landing input {
    width: 100%;
    flex: 1;
    box-sizing: border-box; /* Ensure proper sizing for input fields */
}

textarea.form-input {
    height: 150px;
    resize: none;
}

.submit-btn-landing {
    background-color: white;
    font-weight: bolder;
    color: #0032cf;
    border: none;
    width: 100%;
    padding: 15px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submit-btn:hover {
    background-color: rgb(0, 54, 162);
}


/* Style the placeholder text to be white */
::placeholder {
    color: white;
    opacity: 1; /* Ensures full opacity */
}

:-ms-input-placeholder { /* For Internet Explorer */
    color: white;
}

::-ms-input-placeholder { /* For Edge */
    color: white;
}

/* Ensure the select dropdown matches the form input styling */
select.form-input-landing {
    width: 106.5%;
    background: transparent;
    color: white;
    padding: 10px;
}

/* Optional: Ensures the options themselves have default colors */
select.form-input-landing option {
    color: black; /* Option text color */
    border-color: transparent;
    background: white; /* Option background */
}

.contact-us-home {
    background-image: url('../../../../assets/HomeEndBackground.webp');
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }
  
  
  .contact-us-home-home-footer {
    position: relative;
    margin-top: 50px;
    display: flex;
    justify-content: center; /* Center the entire footer content horizontally */
    align-items: center; /* Center the entire footer content vertically */
    padding: 20px;
    margin-bottom: -100px;
  }
  
  .contact-us-home-home-footer img {
    width: 90%; /* Full-width image */
    position: relative; /* Position for content overlay */
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    align-items: flex-start; /* Align content to the left */
    position: absolute; /* Keeps content inside the image */
    padding-left: 25%;
    border-radius: 10px;
    height: 100%; /* Full height */
    width: 100%; /* Full width */
  }
  
  .footer-content h3 {
    font-size: 3rem;
    margin-bottom: 10px;
    color: #0032cf;
    margin: 0;
    padding: 0;
    line-height: 1.2;
  }
  
  .subscribe-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%; /* Full width */
    max-width: 550px; /* Adjust width as needed */
    border: 1px solid black;
    border-radius: 10px; /* Rounded corners */
    overflow: hidden;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 7%;
  }
  
  .subscribe-form .input-field {
    flex-grow: 1; /* Take up available space */
    padding-left: 15px;
    border: none;
    outline: none;
    font-size: 1rem;
  }
  
  .subscribe-form .input-field::placeholder {
    color: black;
    font-size: 1.3rem;
  }
  .subscribe-form .input-field:focus::placeholder {
    color: transparent; /* Make the placeholder invisible */
  }
  
  .subscribe-button {
    background-color: #0032cf;
    color: white;
    border: none;
    padding: 25px 35px;
    margin-right: 5px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 200;
  }
  
  /* Responsive Styles */
  
  /* Tablets and smaller desktops */
  @media (max-width: 1040px) {
    .footer-content {
      padding-left: 25%;
    }
    .footer-content h3 {
      font-size: 2.5rem;
    }
    .subscribe-form {
      max-width: 450px; /* Adjust form width */
      margin-top: 6%;
    }
    .subscribe-button {
      height: auto; /* Ensure button adjusts */
      padding: 20px 30px;
      font-size: 1.5rem;
    }
    .contact-us-home-icon img{
      margin-bottom: -130px;
  }
  }
  
  /* Mobile devices */
  @media (max-width: 900px) {

    .contact-us-home-home-footer {
      margin-bottom: -80px;
    }

    .footer-content {
      padding-left: 25%;
    }
    .footer-content h3 {
      font-size: 2rem;
    }
    .subscribe-form {
      max-width: 350px; /* Adjust form width */
      flex-direction: column; /* Stack input and button vertically */
      padding: 10px;
      margin-top: 5%;
    }
    .subscribe-form .input-field {
      width: 100%; /* Full width for input */
      margin-bottom: 10px;
    }
    .subscribe-button {
      width: 100%; /* Full width for button */
      /* padding: 20px; */
      font-size: 1.2rem;
    }
  }
  
  /* Small mobile screens */
  @media (max-width: 770px) {

    .contact-us-home-home-footer {
      margin-bottom: -50px;
    }
    .footer-content h3 {
      font-size: 1.5rem; /* Smaller font size */
    }
    .subscribe-form {
      max-width: 300px; /* Narrower form */
      padding-right: 25px;
      margin-top: 4%;
    }
    .subscribe-button {
        width: 250px;
      padding: 15px 25px;
      font-size: 1rem; /* Smaller button text */
    }
  }

    /* Small mobile screens */
    @media (max-width: 672px) {
        .footer-content h3 {
          font-size: 1rem; /* Smaller font size */
        }
        .subscribe-form {
          max-width: 150px; /* Narrower form */
          margin-top: 2%;
        }
        .subscribe-form .input-field::placeholder {
            font-size: 1rem; /* Smaller font size */
          }
        .subscribe-button {
          /* padding: 15px 25px; */
          width: 150px;
          font-size: 1rem;
        }
        .input-field {
            margin-top: 10%;
        }
      }

          /* Small mobile screens */
    @media (max-width: 520px) {

      .contact-us-home-home-footer img {
        width: 100%; /* Full-width image */
        position: relative; /* Position for content overlay */
      }
      .input-field {
        margin-top: 10%;
    }
      .contact-us-home-icon img{
        padding-top: 80px;
        width: 8%;
        margin-bottom: -110px;
    }
        .footer-content h3 {
          font-size: 0.9rem; /* Smaller font size */
        }
        .subscribe-form {
          height: 50px;
          text-align: center;
          display: flex;
          flex-direction: row;
          border: transparent;
        }

        .subscribe-form .input-field {
          font-size: 0.6rem;
          margin-bottom: 0;
          align-self: self-start;
          margin-top: -15px;
          margin-left: -10px;
          margin-right: 2px;
          height: 30px;
        }

        .subscribe-form .input-field::placeholder {
            font-size: 0.6rem; /* Smaller font size */
            text-align: center;
            border: 1px solid rgb(104, 104, 104);
            border-radius: 4px;
            width: 100%;
            height: 20px;
          }
        .subscribe-button {
          width: 100%;
          padding-right: 0;
          margin-left: 5px;
          border-radius: 5px;
          padding: 7px 7px;
          font-size: 0.6rem;
          text-align: center;
        }

        .contact-us-home-home-footer img {
          width: 105%; /* Full-width image */
          position: relative; /* Position for content overlay */
          height: 150px;
        }

        .footer-content {
          padding-top: 5%;
          padding-bottom: 5%;
        }
      }
  