/* Base Styles for Info Section */
.info-section {
  color: white;
  padding: 90px 0;
  padding-left: 160px;
}

.info-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
}

.info-item {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.info-item img {
  width: 70px;
  margin-right: 60px;
}

.info-item h3, .info-item p {
  text-align: left;
}

.info-item h3 {
  font-size: 24px;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.info-item p {
  font-size: 16px;
  line-height: 1.5;
}

/* Media Queries */
@media (max-width: 1200px) {
  .info-section {
    padding-left: 100px;
  }

  .info-item img {
    width: 60px;
    margin-right: 50px;
  }

  .info-item h3 {
    font-size: 22px;
  }

  .info-item p {
    font-size: 15px;
  }
}

@media (max-width: 992px) {
  .info-section {
    padding-left: 80px;
  }

  .info-item img {
    width: 55px;
    margin-right: 40px;
  }

  .info-item h3 {
    font-size: 20px;
  }

  .info-item p {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .info-section {
    padding-left: 40px;
    padding-right: 40px;
  }

  .info-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .info-item img {
    width: 50px;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .info-item h3 {
    font-size: 18px;
  }

  .info-item p {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .info-section {
    padding: 60px 20px;
  }

  .info-item img {
    align-self: center;
    width: 45px;
  }

  .info-item h3 {
    text-align: center;
    font-size: 16px;
  }
  
  .info-item p {
    text-align: center;
    font-size: 13px;
  }
}

@media (max-width: 350px) {
  .info-section {
    padding: 50px 15px;
  }

  .info-item img {
    width: 40px;
  }

  .info-item h3 {
    font-size: 14px;
  }

  .info-item p {
    font-size: 12px;
  }
}
