/* Base styles for navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: background-color 0.3s ease, padding 0.3s ease;
}

/* White transparent navbar with black text (for /app) */
.navbar.white-transparent {
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
}

.navbar.white-transparent .logo img{
  width: 200px;
  height: auto;
}

.navbar.white-transparent .nav-links a {
  color: black;
}

.navbar.white-transparent .hamburger {
  color: black;
}

/* Black navbar */
.navbar.black {
  background-color: rgba(0, 0, 0, 0.9);
}

.navbar.black .nav-links a {
  color: white;
}

.navbar.black .hamburger {
  color: white;
}

/* Logo styles */
.navbar .logo img {
  width: auto;
  height: 40px;
  transition: height 0.3s ease;
}

/* ... (previous styles remain the same) ... */

/* Navigation links */
.nav-links {
  display: flex;
  gap: 2rem;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-links a {
  text-decoration: none;
  font-weight: 400;
  font-size: 1rem;
  transition: color 0.3s ease, transform 0.2s ease;
  position: relative;
  padding-bottom: 5px;
}

.nav-links a:hover {
  transform: translateY(-2px);
}

/* Active link styles */
.nav-links a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background: linear-gradient(90deg, #0032CF, #3766ff);
  transition: width 0.3s ease, left 0.3s ease;
}

.nav-links a.active::after {
  width: 100%;
  left: 0;
}

/* Newly selected link animation */
.nav-links a.newly-selected::after {
  width: 100%;
  left: 0;
  animation: expandLine 0.3s ease-out;
}

@keyframes expandLine {
  0% {
    width: 0;
    left: 50%;
  }
  100% {
    width: 100%;
    left: 0;
  }
}

/* ... (rest of the styles remain the same) ... */

/* Hamburger menu for mobile */
.hamburger {
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

/* Media query for responsive design */
@media (max-width: 1024px) {
  .navbar {
    padding: 0.75rem 5%;
  }

  .navbar .logo img {
    height: 32px;
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 0;
    text-align: center;
    transition: all 0.3s ease-in-out;
    max-height: 0;
    overflow: hidden;
  }

  .navbar.white-transparent .nav-links {
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
  }

  .nav-links.active {
    max-height: 300px; /* Adjust this value based on your content */
    padding: 1rem 0;
  }

  .nav-links a {
    padding: 0.5rem 0;
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  .nav-links.active a {
    opacity: 1;
    transform: translateY(0);
  }

  .hamburger {
    display: block;
  }

  /* Stagger animation for nav links */
  .nav-links.active a:nth-child(1) { transition-delay: 0.1s; }
  .nav-links.active a:nth-child(2) { transition-delay: 0.2s; }
  .nav-links.active a:nth-child(3) { transition-delay: 0.3s; }
  .nav-links.active a:nth-child(4) { transition-delay: 0.4s; }
  .nav-links.active a:nth-child(5) { transition-delay: 0.5s; }
}

/* Scroll-based styles */
.navbar.scrolled {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: rgba(0, 0, 0, 0.95);
}

.navbar.scrolled .logo img {
  height: 32px;
}

/* Animation for navbar expansion */
.navbar.expanded {
  padding-bottom: 1rem;
}

/* Transition class for smoother animations */
.navbar.transitioning .nav-links {
  transition: all 0.3s ease-in-out;
}