.footer {
  background-color: #181818;
  padding: 20px;
  color: #fff;
  font-family: 'Arial', sans-serif;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
}

.title {
  font-size: 2rem;
  font-weight: 100;
  margin-bottom: 20px;
}

.footer-sections {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  width: 100%;
  gap: 20px;
}

.footer-section {
  flex: 1 1 200px;
  margin-bottom: 20px;
}

.footer-section h3 {
  font-size: 1.2rem;
  font-weight: 50;
  margin-bottom: 15px;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
}

.footer-section ul li a {
  color: #fff;
  text-decoration: none;
}

.footer-section ul li a:hover {
  text-decoration: underline;
}

.social-icons {
  display: flex;
  margin-top: 10px;
}

.social-icons a {
  margin-right: 15px;
}

.social-icons img {
  width: 24px;
  height: 24px;
}

.app-links {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.app-links img {
  width: 170px;
  max-width: 100%;
  height: auto;
}

.footer-bottom {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
}

.footer-bottom p {
  text-align: start;
  margin: 0;
  font-size: 0.9rem;
}

.logo-container img {
  width: 170px;
  max-width: 100%;
  height: auto;
}

.app-logo-container img {
  width: 120px;
  max-width: 100%;
  height: auto;
}
/* 
.language-switcher {
  position: relative;
  display: inline-block;
}

.language-selector {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px 12px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #333;
  color: #fff;
}

.language-selector:hover {
  background-color: #444;
}

.globe-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  filter: brightness(0) invert(1);
}

.arrow {
  margin-left: 8px;
  transition: transform 0.3s ease;
  color: #fff;
}

.arrow.up {
  transform: rotate(180deg);
}


.language-dropdown {
  position: absolute;
  bottom: 100%;
  left: 0;
  margin-bottom: 5px;
  list-style: none;
  padding: 0;
  background-color: #333;
  border: 1px solid #444;
  border-radius: 5px;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.3); 
  z-index: 10;
  color: #fff; 
}

.language-dropdown li {
  padding: 10px;
  cursor: pointer;
  color: #fff; 
}

.language-dropdown li:hover {
  background-color: #444; 
}

.language-dropdown li:not(:last-child) {
  border-bottom: 1px solid #555; 
} */




/* 
@media (max-width: 768px) {
  .title{
    align-self: center;
  }
  .footer-sections {
    flex-direction: column;
  }

  .footer-section {
    flex-basis: 100%;
  }

  .title {
    font-size: 1.5rem;
  }

  .footer-bottom p {
    text-align: start;
  }
} */

@media (max-width: 480px) {

  .title{
    align-self: center;
    font-size: 1.2rem;
  }

  .footer {
    padding: 15px;
  }

  .footer-section h3 {
    font-size: 1rem;
  }

  .app-links img {
    width: 100px;
  }



}